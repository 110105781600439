import { isMobile } from '@common/device-manager';
import { useAirbridgeDeeplinks, WEB_TO_APP_DEEP_LINK } from '@common/marketing-tracker';
import { useBenefitLandingPopup } from '@common/overlay-manager';

export function useAppUsageConversionService() {
  const { airbridgeDeeplinks } = useAirbridgeDeeplinks();
  const { open } = useBenefitLandingPopup();

  const handleBannerClick = () => {
    if (isMobile()) {
      airbridgeDeeplinks({ deeplink: WEB_TO_APP_DEEP_LINK.HOME, ctaParams: { cta_param_1: '' } });
    } else {
      open();
    }
  };

  return { handleBannerClick };
}
